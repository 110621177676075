import { postData } from "../../shared/utils";

const TREE_TYPES = {
  TEAM: "TEAM",
  MANAGEMENT: "MANAGEMENT",
};

export function adminOrgCsvs(args) {
  const { account } = args;

  return {
    loading: false,
    showInstructions: false,
    account,
    isDragging: false,
    hasError: false,
    file: null,
    headers: [],
    rows: [],
    sampledRows: [],
    fallbacksAdded: 0,
    fields: [
      { key: "name", label: "User name" },
      { key: "email", label: "User email" },
      { key: "team_name", label: "Team name" },
      { key: "mgr_name", label: "Manager name" },
      { key: "mgr_email", label: "Manager email" },
      { key: "parent_team", label: "Parent Team name" },
      { key: "grandparent_team", label: "Grandparent Team name" },
    ],
    selectedColumns: {
      name: "name",
      email: "email",
      team_name: "direct_team",
      mgr_name: "manager",
      mgr_email: "manager_email",
      parent_team: "parent_team",
      grandparent_team: "grandparent_team",
    },
    showPreview: false,
    teamsPreview: {},
    squads: [],
    treeType: TREE_TYPES.TEAM, // "MANAGEMENT" or "TEAM"
    treeTypes: Object.keys(TREE_TYPES),
    expandedSquadsIds: {},
    init() {},
    handleDrop(event) {
      this.hasError = false;
      event.preventDefault();
      this.file = event.dataTransfer.files[0];
      this.isDragging = false;
      this.validateFile();
    },
    processFile() {
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.parseCSV(csvContent);
        };
        reader.readAsText(this.file);
      }
    },
    validateFile() {
      this.errorMessage = "";
      if (this.file) {
        // Check the MIME type or extension
        const mimeType = this.file.type;
        const fileName = this.file.name;

        // Validate MIME type or file extension
        if (
          mimeType !== "text/csv" &&
          mimeType !== "application/vnd.ms-excel" &&
          !fileName.endsWith(".csv")
        ) {
          this.errorMessage = "Invalid file type. Please upload a CSV file.";
          this.hasError = true;
          this.file = null;
          return;
        }

        this.processFile(); // Process only if it's a valid CSV file
      }
    },
    async parseCSV(csvContent) {
      const Papa = await import("papaparse");
      Papa.parse(csvContent, {
        header: true,
        complete: (result) => {
          this.headers = result.meta.fields;
          this.rows = result.data;
          this.sampleRandomRows();
        },
        skipEmptyLines: true,
      });
    },
    sampleRandomRows() {
      if (this.rows.length > 0) {
        // Shuffle the rows and take a random sample of 20 rows
        const shuffled = [...this.rows].sort(() => 0.5 - Math.random());
        this.sampledRows = shuffled.slice(0, 20);
      }
    },
    addFallbackManagerField() {
      const fallbackIndex = this.fallbacksAdded + 1;
      this.fields.push({
        key: `fallback_mgr_name_${fallbackIndex}`,
        label: `Fallback Manager name ${fallbackIndex}`,
        canDelete: true,
      });
      this.fields.push({
        key: `fallback_mgr_email_${fallbackIndex}`,
        label: `Fallback Manager email ${fallbackIndex}`,
        canDelete: true,
      });
      this.fallbacksAdded += 1;
    },
    removeFallbackManagerSet() {
      if (this.fallbacksAdded > 0) {
        this.fields = this.fields.slice(0, -2);
        this.fallbacksAdded -= 1;
      }
    },
    preview() {
      const managersSet = new Set();

      const employees = this.rows.map((row) => {
        if (row["BUSINESS_TITLE"]?.includes("VP")) {
          managersSet.add(row[this.selectedColumns["mgr_email"]]);
        }

        return {
          name: row[this.selectedColumns["name"]],
          email: row[this.selectedColumns["email"]],
          team: row[this.selectedColumns["team_name"]],
          mgr_name: row[this.selectedColumns["mgr_name"]], // TODO: fallback logic here to traverse back through the fallbacks if they don't exist
          mgr_email: row[this.selectedColumns["mgr_email"]],
          parent_team: row[this.selectedColumns["parent_team"]],
          grandparent_team: row[this.selectedColumns["grandparent_team"]],
        };
      });

      if (
        this.treeType === TREE_TYPES.MANAGEMENT &&
        !this.selectedColumns["mgr_email"]
      ) {
        this.treeType = TREE_TYPES.TEAM;
      }

      const preprocessedManagerDataPinterest = this.preprocessManagerTeams(
        employees,
        managersSet,
      );

      const pinterestHierarchy = this.buildTeamHierarchy(
        preprocessedManagerDataPinterest,
      );

      if (!pinterestHierarchy) {
        const result =
          this.treeType == TREE_TYPES.MANAGEMENT
            ? this.buildOrgHierarchy(employees)
            : this.buildTeamHierarchy(
                this.preprocessTeams(employees, managersSet),
              );
        this.teamsPreview = result;
        console.log(`result:`, result);
      } else {
        this.teamsPreview = pinterestHierarchy;
      }

      this.squads = this.newFlattenHierarchy(this.teamsPreview); // May need a different flattening process based on the tree type
      this.showPreview = true;
    },
    buildOrgHierarchy(employees) {
      // Create a map of all employees by their email
      const employeeMap = new Map(employees.map((emp) => [emp.email, emp]));

      // Find the top-level manager(s)
      const topManagers = employees.filter(
        (emp) => !employeeMap.has(emp.mgr_email),
      );

      let teamIdCounter = 1;

      const hasTeamName = this.selectedColumns["team_name"];

      // Function to recursively build the hierarchy
      function buildHierarchy(manager, depth = 1, parent_id) {
        const temp_id = `T${teamIdCounter++}`;
        const subordinates = employees.filter(
          (emp) => emp.mgr_email === manager.email,
        );
        const subTeams = subordinates.map((sub) =>
          buildHierarchy(sub, depth + 1, temp_id),
        );

        const teamName = hasTeamName
          ? subTeams.length > 0
            ? manager.team
            : manager.team
          : manager.mgr_email;

        const obj = {
          name: manager.name,
          email: manager.email,
          mgr_email: manager.mgr_email,
          team: teamName,
          parent_team: manager.parent_team,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          subTeams: subTeams,
        };

        return obj;
      }

      // Group top managers by team
      const teamGroups = topManagers.reduce((groups, manager) => {
        const team = manager.team;
        if (!groups[team]) {
          groups[team] = [];
        }
        groups[team].push(manager);
        return groups;
      }, {});

      const managerGroups = topManagers.reduce((groups, manager) => {
        const team = manager.mgr_email;
        if (!groups[team]) {
          groups[team] = [];
        }
        groups[team].push(manager);
        return groups;
      }, {});

      const groupsToMatch = hasTeamName ? teamGroups : managerGroups;

      // Build the hierarchy for each team
      const hierarchy = Object.entries(groupsToMatch).map(
        ([team, managers]) => {
          const temp_id = `T${teamIdCounter++}`;

          return {
            team: team,
            email: team,
            team_names: [
              ...new Set(managers.map((m) => m.parent_team).filter(Boolean)),
            ],
            depth: 0,
            temp_id: temp_id,
            is_parent: true,
            subTeams: managers.map((manager) =>
              buildHierarchy(manager, 1, temp_id),
            ),
          };
        },
      );

      return hierarchy.sort((a, b) => {
        // Sort by is_parent first
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        // If is_parent is the same, sort alphabetically by team
        return a.team.localeCompare(b.team);
      });
    },
    newFlattenHierarchy(hierarchy) {
      const flatArray = [];

      function flattenNode(node, parentId = null) {
        // Push the current node (excluding the subTeams) into the flat array
        flatArray.push({
          name: node.name,
          email: node.email,
          mgr_email: node.mgr_email,
          team: node?.team,
          temp_id: node.temp_id,
          parent_id: parentId,
          is_parent: node.is_parent,
          depth: node.depth,
          members: node.members,
          parent_team_name: node.parent_team,
        });

        // Recursively flatten the subTeams (children)
        node.subTeams.forEach((child) => flattenNode(child, node.temp_id));
      }

      // Start flattening from the top-level nodes
      hierarchy.forEach((team) => flattenNode(team));

      return flatArray;
    },
    preprocessManagerTeams(employees, managersSet) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        const teamKey = `${emp.team}-${emp.mgr_email}`;

        if (!teamMap.has(teamKey)) {
          // Add the team if it doesn't already exist
          teamMap.set(teamKey, {
            team: emp.team,
            mgr_email: emp.mgr_email,
            parent_team: emp.parent_team || null,
            grandparent_team: emp.grandparent_team || null,
            members: [],
          });
        }

        // Adding only direct IC's team
        if (!managersSet.has(emp.email)) {
          teamMap.get(teamKey).members.push(emp.email);
        }
      });

      // Second Pass: Assign parent_team based on mgr_email
      teamMap.forEach((teamValue) => {
        if (teamValue.mgr_email) {
          // Find the manager's employee record
          const manager = employees.find(
            (emp) => emp.email === teamValue.mgr_email,
          );
          if (manager && manager.team) {
            // Assign the manager's team as the parent_team
            teamValue.parent_team = manager.team;
          }
        }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    preprocessTeams(employees, managersSet) {
      // Create a map to store teams with their members
      const teamMap = new Map();

      employees.forEach((emp) => {
        if (!teamMap.has(emp.team)) {
          // Add the team if it doesn't already exist
          teamMap.set(emp.team, {
            team: emp.team,
            parent_team: emp.parent_team || null,
            grandparent_team: emp.grandparent_team || null,
            members: [],
          });
        }
        // Adding only direct IC's team
        if (!managersSet.has(emp.email)) {
          teamMap.get(emp.team).members.push(emp.email);
        }
      });

      // Convert the map values to an array and return
      return Array.from(teamMap.values());
    },
    buildTeamHierarchy(teams) {
      // Step 1: Create a map for quick team lookup by team name
      const teamMap = new Map();
      teams.forEach((team) => {
        teamMap.set(team.team, team);
      });

      // Step 2: Preprocess to add missing parent teams based on grandparent_team
      const allTeams = [...teams]; // Clone the original teams array
      let addedNewTeam;

      do {
        addedNewTeam = false;
        // Iterate over a copy of allTeams to prevent infinite loops
        const currentTeams = [...allTeams];
        currentTeams.forEach((team) => {
          // Handle missing parent_team
          if (team.parent_team && !teamMap.has(team.parent_team)) {
            if (team.grandparent_team) {
              // Create a placeholder for the missing parent_team
              const parentTeam = {
                team: team.parent_team,
                parent_team: team.grandparent_team, // Link to grandparent_team
                grandparent_team: null, // Optional: set to null or derive if possible
                members: 0, // Default value; adjust as needed
              };
              // Add the new parent team only if it hasn't been added already
              if (!teamMap.has(parentTeam.team)) {
                allTeams.push(parentTeam);
                teamMap.set(parentTeam.team, parentTeam);
                addedNewTeam = true;
                console.log(`Added missing parent team: ${parentTeam.team}`);
              }

              // Additionally, ensure that the grandparent_team exists
              if (
                parentTeam.parent_team &&
                !teamMap.has(parentTeam.parent_team)
              ) {
                const grandparentTeam = {
                  team: parentTeam.parent_team,
                  parent_team: null, // Assuming top-level if not specified
                  grandparent_team: null,
                  members: 0, // Default value; adjust as needed
                };
                if (!teamMap.has(grandparentTeam.team)) {
                  allTeams.push(grandparentTeam);
                  teamMap.set(grandparentTeam.team, grandparentTeam);
                  addedNewTeam = true;
                  console.log(
                    `Added grandparent team: ${grandparentTeam.team}`,
                  );
                }
              }
            } else {
              // If there's no grandparent_team, consider parent_team as a top-level team
              const parentTeam = {
                team: team.parent_team,
                parent_team: null,
                grandparent_team: null,
                members: 0,
              };
              if (!teamMap.has(parentTeam.team)) {
                allTeams.push(parentTeam);
                teamMap.set(parentTeam.team, parentTeam);
                addedNewTeam = true;
                console.log(`Added top-level parent team: ${parentTeam.team}`);
              }
            }
          }

          // Handle missing grandparent_team separately
          if (team.grandparent_team && !teamMap.has(team.grandparent_team)) {
            const grandparentTeam = {
              team: team.grandparent_team,
              parent_team: null, // Assuming top-level if not specified
              grandparent_team: null,
              members: 0, // Default value; adjust as needed
            };
            if (!teamMap.has(grandparentTeam.team)) {
              allTeams.push(grandparentTeam);
              teamMap.set(grandparentTeam.team, grandparentTeam);
              addedNewTeam = true;
              console.log(`Added grandparent team: ${grandparentTeam.team}`);
            }
          }
        });
      } while (addedNewTeam); // Continue until no new teams are added

      // Step 3: Identify Top-Level Teams
      const topTeamsSet = new Set();

      allTeams.forEach((team) => {
        if (!team.parent_team && team.team) {
          topTeamsSet.add(team.team);
        }
      });

      if (topTeamsSet.size == 0) {
        // NOTE: Experiment for BNY. Using all the grandparent teams as the toplevel teams.
        allTeams.forEach((team) => {
          if (team.team && team.grandparent_team) {
            topTeamsSet.add(team.grandparent_team);
          }
        });
      }
      const topTeamsArray = Array.from(topTeamsSet);

      // Convert the Set to an Array of team objects
      const topTeams = topTeamsArray
        .map((teamName) => allTeams.find((team) => team.team === teamName))
        .filter((team) => team); // Filter out undefined if any

      let teamIdCounter = 1;
      const visited = new Set();

      // Recursive function to build the hierarchy
      function buildHierarchy(team, depth = 0, parent_id = null) {
        const temp_id = `T${teamIdCounter++}`;

        // Detect circular references
        if (visited.has(team.team)) {
          console.warn(`Circular reference detected for team: ${team.team}`);
          return null; // Skip to prevent infinite loop
        }
        visited.add(team.team);

        // Find sub-teams based on parent_team
        const subTeams = allTeams
          .filter((sub) => sub.parent_team === team.team)
          .map((sub) => buildHierarchy(sub, depth + 1, temp_id))
          .filter((subTeam) => !!subTeam); // Remove nulls from circular references

        return {
          name: team.team,
          team: team.team,
          parent_team: team.parent_team || null,
          mgr_email: team.mgr_email || null,
          temp_id: temp_id,
          parent_id: parent_id,
          is_parent: subTeams.length > 0,
          depth: depth,
          members: team.members,
          subTeams: subTeams.sort((a, b) => {
            if (a.is_parent !== b.is_parent) {
              return b.is_parent - a.is_parent;
            }
            return a.team.localeCompare(b.team);
          }),
        };
      }

      // Step 4: Build the hierarchy starting from top-level teams
      const hierarchy = topTeams
        .map((team) => buildHierarchy(team))
        .filter((team) => team);

      // Step 5: Sort the final hierarchy
      return hierarchy.sort((a, b) => {
        if (a.is_parent !== b.is_parent) {
          return b.is_parent - a.is_parent;
        }
        return a.team.localeCompare(b.team);
      });
    },
    get treeSquads() {
      this.squads.map((s) => {
        return s;
      });

      const squadsToReturn = this.squads?.filter((s) => {
        const value =
          s.depth == 0 ||
          s.expanded == true ||
          this.expandedSquadsIds[s.parent_id] == true;

        return value;
      });

      return squadsToReturn;
    },
    toggleSquad(targetSquad) {
      this.squads = this.squads.map((squad) => {
        if (targetSquad.temp_id === squad.parent_id) {
          squad.parentExpanded = targetSquad.expanded;
        }

        if (targetSquad.temp_id === squad.temp_id) {
          targetSquad.expanded = squad.expanded = !squad.expanded;

          if (targetSquad.expanded) {
            this.expandedSquadsIds[targetSquad.temp_id] = true;
          } else {
            delete this.expandedSquadsIds[targetSquad.temp_id];
          }
        }

        return squad;
      });

      if (!targetSquad.expanded) {
        this.collapseChildren(targetSquad);
      }
    },
    collapseChildren(parent) {
      this.squads.forEach((squad) => {
        if (squad.parent_id !== parent.temp_id) return;

        if (squad.is_parent) this.collapseChildren(squad);

        squad.expanded = false;
        squad.parentExpanded = false;
        delete this.expandedSquadsIds[squad.temp_id];
      });
    },
    submit() {
      console.log("submitting....", this.squads);

      const url = "/admin/org_csvs";

      postData(url, { squads: this.squads }).then((res) => {
        console.log(`res:`, res);
      });
    },
    downloadJSON() {
      const json = JSON.stringify(this.squads, null, 2); // Convert squads to JSON
      const blob = new Blob([json], { type: "application/json" }); // Create a Blob
      const url = URL.createObjectURL(blob); // Create a URL for the Blob

      const a = document.createElement("a"); // Create an anchor element
      a.href = url; // Set the href to the Blob URL
      a.download = "squads.json"; // Set the download attribute
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Programmatically click the anchor to trigger the download
      document.body.removeChild(a); // Remove the anchor from the document
      URL.revokeObjectURL(url); // Clean up the URL object
    },
  };
}
