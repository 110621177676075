export function benchmarkSegments(args) {
  const {
    accountSegmentId,
    benchmarkSegments,
    benchmarkSegmentId,
    updateUserBenchmarkUrl,
  } = args;

  return {
    open: false,
    benchmarkSegments,
    accountSegmentId,
    benchmarkSegmentId,
    updateUserBenchmarkUrl,
    toggle() {
      if (this.open) {
        this.open = false;
        return;
      }

      this.open = true;
    },
    isSelected(benchmarkSegment) {
      return benchmarkSegment.id == this.benchmarkSegmentId;
    },
    select(benchmarkSegment) {
      this.benchmarkSegmentId = benchmarkSegment.id;
    },
    isAccountSegmentId(benchmarkSegment) {
      return benchmarkSegment.id == this.accountSegmentId;
    },
    get selectedBenchmark() {
      return benchmarkSegments.find(
        (benchmarkSegment) => benchmarkSegment.id == this.benchmarkSegmentId,
      );
    },
    updateBenchmarkSegmentForUser() {
      fetch(this.updateUserBenchmarkUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          benchmark_segment_id: this.benchmarkSegmentId,
        }),
      }).then(() => {
        this.$el.dispatchEvent(
          new CustomEvent("change-benchmark-segment", {
            bubbles: true,
            detail: { benchmarkSegment: this.selectedBenchmark },
          }),
        );
      });
    },
  };
}
